import React, { useState, useEffect } from 'react';
import { 
  Container, TextField, Typography, Card, CardContent, Box, IconButton,
  styled, useTheme, Chip
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.5)',
  backdropFilter: 'blur(15px)',
  border: '2px solid rgba(255, 255, 255, 0.3)',
  borderRadius: '15px',
  boxShadow: 'none',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(15px)',
    borderRadius: '15px',
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
      borderRadius: '15px',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.primary,
  },
}));

const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
});

const LargeDot = styled(Typography)(({ theme }) => ({
  fontSize: '5rem',
  lineHeight: 0,
  color: theme.palette.primary.main,
}));

const ExperiencedList = ({ title, data }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();

  const filteredData = data
    .filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const createAnchorId = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
  };

  const copyLinkToClipboard = (title) => {
    const anchorId = createAnchorId(title);
    const url = `${window.location.origin}${window.location.pathname}#${anchorId}`;
    navigator.clipboard.writeText(url);
  };

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      }
    }
  }, []);

  return (
    <StyledContainer maxWidth="md">
      <TitleContainer>
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary }}>
          {title}
        </Typography>
        <LargeDot>.</LargeDot>
      </TitleContainer>
      <Typography 
        variant="body1" 
        sx={{
          color: theme.palette.text.secondary,
          fontStyle: 'italic',
          marginBottom: theme.spacing(2),
        }}
      >
        A text, a sound, a video, ... a moment?
        <br />
        <br />
      </Typography>
      <StyledTextField
        fullWidth
        label="Search a keyword"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {filteredData.map((item) => {
        const anchorId = createAnchorId(item.title);
        return (
          <StyledCard key={item.id} id={anchorId}>
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>{item.title}</Typography>
                <IconButton onClick={() => copyLinkToClipboard(item.title)} size="small" sx={{ color: theme.palette.primary.main }}>
                  <LinkIcon />
                </IconButton>
              </Box>
              <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary }}>
                {`${item.type} by ${item.artist} (${item.year})`}
              </Typography>
              <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary, marginTop: theme.spacing(1) }}>
                {item.content}
              </Typography>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>Note: {item.note}</Typography>
              <Box mt={1} display="flex" flexWrap="wrap" gap={1}>
                {item.tags.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    size="small"
                    variant="outlined"
                    sx={{
                      background: 'rgba(255, 255, 255, 0.9)',
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      color: theme.palette.text.primary,
                    }}
                  />
                ))}
              </Box>
            </CardContent>
          </StyledCard>
        );
      })}
    </StyledContainer>
  );
};

export default ExperiencedList;