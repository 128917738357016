import { GlobalStyles, useTheme } from '@mui/material';

function CustomScrollbar() {
  const theme = useTheme();
  
  return (
    <GlobalStyles
      styles={{
        'html': {
          scrollbarGutter: 'stable',
        },
        '*::-webkit-scrollbar': {
          width: '16px',
          height: '16px',
        },
        '*::-webkit-scrollbar-track': {
          background: theme.palette.action.hover,
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.primary.main,
          border: '3px solid transparent',
          backgroundClip: 'padding-box',
          borderRadius: '8px',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '*::-webkit-scrollbar-button': {
          width: '16px',
          height: '16px',
          borderRadius: '50px',
          background: theme.palette.primary.light,
        },
        '*::-webkit-scrollbar-button:hover': {
          background: theme.palette.primary.main,
          borderRadius: '50px'
        },
        '*::-webkit-scrollbar-button:vertical:start:decrement': {
          borderWidth: '0 8px 8px 8px',
          borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        },
        '*::-webkit-scrollbar-button:vertical:end:increment': {
          borderWidth: '8px 8px 0 8px',
          borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
        },
        '*::-webkit-scrollbar-button:horizontal:start:decrement': {
          borderWidth: '8px 8px 8px 0',
          borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
        },
        '*::-webkit-scrollbar-button:horizontal:end:increment': {
          borderWidth: '8px 0 8px 8px',
          borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
        },
      }}
    />
  );
}

export default CustomScrollbar;