import React, { useState } from 'react';
import { 
  AppBar, Toolbar, IconButton, 
  Drawer, List, ListItem, Box, useMediaQuery, useTheme,
  styled
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';

const menuItems = [
  { title: 'Written', path: '/written' },
  { title: 'Thought', path: '/thought' },
  { title: 'Experienced', path: '/experienced'},
  { title: 'Playground', path: '/playground'}
//   { title: 'Seen', path: '/seen' },
//   { title: 'Heard', path: '/heard' },
];

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    paddingTop: theme.spacing(2), // Add top padding for all screen sizes
    [theme.breakpoints.down(700)]: {
      paddingTop: theme.spacing(2), // Keep the existing top padding for mobile view
    },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
[theme.breakpoints.down(700)]: {
    minHeight: 'auto', // Remove default min-height
    // padding: 0, // Remove default padding
},
}));
const MenuContainer = styled(Box)(({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.00)',
    borderRadius: '15px',
    padding: '5px 20px',
    border: '2px solid rgba(255, 255, 255, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0 auto',
  }));

  const MenuButton = styled(RouterLink)(({ theme }) => ({
    color: theme.palette.text.primary,
    position: 'relative',
    textDecoration: 'none',
    padding: theme.spacing(1),
    display: 'inline-block', // Add this line
    '&::before': {
      content: '"/"',
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
      fontWeight: 900,
      fontSize: '1.2em',
      position: 'relative',
      top: '-0.05em',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 3,
      left: 0, // Change this from '50%' to 0
      width: 0,
      height: '2px',
      backgroundColor: theme.palette.primary.main,
      transition: 'width 0.3s ease',
    },
    '&:hover::after': {
      width: '100%',
    },
  }));
  
  const DrawerMenuItem = styled(RouterLink)(({ theme }) => ({
    fontSize: '2rem',
    margin: theme.spacing(2, 0),
    position: 'relative',
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&::before': {
      content: '"/"',
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
      fontWeight: 900, // Increase font weight to make it thicker
      fontSize: '1.2em', // Slightly increase font size
      position: 'relative',
    //   top: '-0.05em', // Adjust vertical alignment
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      width: 0,
      height: '2px', // Ensure this matches the desired thickness
      backgroundColor: theme.palette.primary.main,
      transition: 'width 0.3s ease, left 0.3s ease',
    },
    '&:hover::after': {
      width: '100%',
      left: 0,
    },
  }));

  const FullScreenDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.1)', // Very transparent white background
      backdropFilter: 'blur(15px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

const Layout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:700px)');
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List>
      {menuItems.map((item) => (
        <ListItem key={item.title} onClick={handleDrawerToggle}>
          <DrawerMenuItem to={item.path}>
            {item.title}
          </DrawerMenuItem>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <StyledAppBar position="static">
        <StyledToolbar>
          <MenuContainer>
            <MenuButton to="/">
              Home
            </MenuButton>
            {isMobile ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{
                  color: 'black',
                  fontSize: '2rem',
                //   padding: theme.spacing(1, 2),
                  marginRight: theme.spacing(0),
                }}
              >
                <MenuIcon fontSize="inherit" />
              </IconButton>
            ) : (
              <Box>
                {menuItems.map((item) => (
                  <MenuButton key={item.title} to={item.path} style={{ marginLeft: theme.spacing(2) }}>
                    {item.title}
                  </MenuButton>
                ))}
              </Box>
            )}
          </MenuContainer>
        </StyledToolbar>
      </StyledAppBar>
      <FullScreenDrawer
        variant="temporary"
        anchor="top"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ position: 'absolute', right: 20, top: 20 }}
        >
          <CloseIcon />
        </IconButton>
        {drawer}
      </FullScreenDrawer>
      <main>{children}</main>
    </>
  );
};

export default Layout;