import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/Home';
import Written from './pages/Written';
import Experienced from './pages/Experienced';
import Thought from './pages/Thought';
import Layout from './components/Layout';
import Playground from './pages/Playground';
import ChatbotApp from './components/ChatbotApp';
import CustomScrollbar from './components/CustomScrollbar';
import { Analytics } from "@vercel/analytics/react"

// Import Libre Franklin font
import '@fontsource/libre-franklin/300.css';
import '@fontsource/libre-franklin/400.css';
import '@fontsource/libre-franklin/500.css';
import '@fontsource/libre-franklin/700.css';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#9ad4f5',
    },
  },
  typography: {
    fontFamily: [
      'Libre Franklin',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CustomScrollbar />
      <CssBaseline />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/written" element={<Written />} />
            <Route path="/experienced" element={<Experienced />} />
            <Route path="/thought" element={<Thought />} />
            <Route path="/playground" element={<Playground />} />
            <Route path="/playground/chatbot" element={<ChatbotApp />} />
          </Routes>
        </Layout>
      </Router>
      <Analytics/>
    </ThemeProvider>
  );
}

export default App;