import React from 'react';
import { 
  Container, Typography, Card, CardContent, Box,
  styled, useTheme, Grid
} from '@mui/material';
import { Link } from 'react-router-dom';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme, available }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: 'rgba(255, 255, 255, 0.5)',
  backdropFilter: 'blur(15px)',
  border: '2px solid rgba(255, 255, 255, 0.3)',
  borderRadius: '15px',
  boxShadow: 'none',
  transition: 'transform 0.3s ease-in-out',
  cursor: available ? 'pointer' : 'default',
  '&:hover': available ? {
    transform: 'translateY(-5px)',
  } : {},
  opacity: available ? 1 : 0.6,
  position: 'relative', // For positioning the status indicator
}));

const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
});

const LargeDot = styled(Typography)(({ theme }) => ({
  fontSize: '5rem',
  lineHeight: 0,
  color: theme.palette.primary.main,
}));

const StatusIndicator = styled(Box)(({ theme, available }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: available ? theme.palette.success.main : theme.palette.error.main,
  position: 'absolute',
  top: 10,
  right: 10,
}));

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
  display: 'block',
  height: '100%',
});

const PlaygroundList = ({ title, data }) => {
  const theme = useTheme();

  return (
    <StyledContainer maxWidth="md">
      <TitleContainer>
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary }}>
          {title}
        </Typography>
        <LargeDot>.</LargeDot>
      </TitleContainer>
      <Typography 
        variant="body1" 
        sx={{
          color: theme.palette.text.secondary,
          fontStyle: 'italic',
          marginBottom: theme.spacing(2),
        }}
      >
        Explore these mini-apps and have fun!
        <br />
        <br />
      </Typography>
      <Grid container spacing={3}>
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            {item.available ? (
              <StyledLink to={`/playground/${item.id}`}>
                <StyledCard available={item.available}>
                  <StatusIndicator available={item.available} />
                  <CardContent>
                    <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      {item.description}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </StyledLink>
            ) : (
              <StyledCard available={item.available}>
                <StatusIndicator available={item.available} />
                <CardContent>
                  <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                    {item.description}
                  </Typography>
                </CardContent>
              </StyledCard>
            )}
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  );
};

export default PlaygroundList;