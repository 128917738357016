import React from 'react';
import ExperiencedList from '../components/ExperiencedList';
import experiencedData from '../data/experienced.json';
import { useDocumentTitle } from '../hooks/useDocumentTitle';

const Experienced = () => {
  useDocumentTitle('Experienced');
  return <ExperiencedList title="Things I have experienced" data={experiencedData} />;
};

export default Experienced;