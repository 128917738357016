import React from 'react';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import PlaygroundList from '../components/PlaygroundList';
import playgroundData from '../data/playground.json';

const Playground = () => {
  useDocumentTitle('Playground');
  return <PlaygroundList title="The Playground" data={playgroundData} />;
};

export default Playground;