import React from 'react';
import ThoughtList from '../components/ThoughtList';
import thoughtData from '../data/thought.json';
import { useDocumentTitle } from '../hooks/useDocumentTitle';

const Thought = () => {
  useDocumentTitle('Thought');
  return <ThoughtList title="Things I have thought" data={thoughtData} />;
};

export default Thought;